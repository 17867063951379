import React, { useContext, useEffect, useState } from "react";
import LayoutNew from "../components/shared/LayoutNew";
import MainBanner from "../pages/slider/MainBanner";
import Casino from "../pages/casino-home/Casino";

import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
import moment from "moment";
import helpers from "../Utils/helpers";
import NoEvent from "../components/NoEvent";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import { FaSearch ,FaBell,FaCog ,FaTv ,FaAngleLeft} from "react-icons/fa";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CurrentNews from "../components/CurrentNews";

const Home = () => {
    var settings = {
        dots: false,
        infinite: false,
        speed: 1000,
        autoplay: false,
        arrows: false,
        slidesToShow: 7,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 3,
            },
          },
        ],
      };
      const {
        setGameTab,
        gameTab,
        keyTime,
        setKeyTime,
        user,
        profileData,
        setSearchTab,
        searchTab,
        parly,
        setParly,
        messagelist,
        setAnnouncement
      } = useContext(AuthContext);
      const navigate = useNavigate();

      const [sportsData, setSportsData] = useState([]);
      const [type, setType] = useState("inplay");
      const [ttype, setttType] = useState("inplay");
      
      const [gameType, setGameType] = useState("");
      const [list, setList] = useState([]);
      const [updatedList, setUpdatedList] = useState([]);
      const [selected, setSelected] = useState({});
      const [inplayCountData, setInplayCountData] = useState({});
      const [sketon, setSketon] = useState(false);

      const fetchAllSport = async (obj) => {
        setSketon(true);
        let temp = !isEmpty(user) ? `&userId=${user.user._id}` : "";
        let parlyType = parly ? `&pagetype=multi` : "";
        const { status, data: response_users } = await apiGet(
          apiPath.userInPlaySport +
            `?type=${!isEmpty(obj) ? obj : type}` +
            temp +
            parlyType
        );
    
        if (status === 200) {
         
          if (response_users.success) {
            if(gameType!==""){
              setSportsData(response_users?.results[type]?.filter((res) => res?.gameType == gameType));
            }else{
              setSportsData(response_users?.results[type]);
            }
            
            setSketon(false);
          }
        }
       
      };
    
      const getData = async () => {
        const { status, data } = await apiGet(apiPath.seriesList+"?domain=bajifair.com");
        if (data?.success) {
          setList(data?.results);
          setUpdatedList(() => {
            setGameType("cricket");
              return filteredArray(
                data?.results?.filter((res) => {
                  return res?.gameType == gameType;
                })
              );
            
          });
        }
      };
    
      const filteredArray = (array) => {
        if (array?.length > 0) {
          let check = array?.filter((res) => {
            return res?.seriesName == "Others";
          });
          if (check?.length > 1) {
            let find = check?.find((res) => {
              return res?.seriesName == "Others";
            });
            return [
              ...array?.filter((res) => {
                return res?.seriesName !== "Others";
              }),
              {
                ...find,
                matchList: check
                  ?.map((res) => {
                    return res?.matchList;
                  })
                  .flat(),
              },
            ];
          } else {
            return array;
          }
        }
      };

    
      useEffect(() => {
        if(type=="leagues"){
          getData();
        }else{
          fetchAllSport();
        }
        
      }, [type,parly,gameType]);

      const inPlayCount = async () => {
        const { status, data: response_users } = await apiGet(apiPath.inPlayCount);
        if (status === 200) {
          if (response_users.success) {
            if (response_users.results) {
              setInplayCountData(response_users.results);
            }
          }
        }
      };
      useEffect(() => {
        inPlayCount();  
      }, []);

  return (
    <LayoutNew>
      {searchTab ? (
          <SearchBar />
        ) : (
          <>
       <MainBanner />
       <CurrentNews
        message={messagelist}
        setAnnouncement={setAnnouncement}
      />
       <div className="d-div">
        <div 
        // style="height: 50px; display: block;"
        ></div>
        <div class="ftype d-flex justify-content-between">
            <ul>
                <li className={type == "inplay" ? "active" : ""}
              onClick={() => {
                setType("inplay");
                setParly(false)
                setGameType("");
              }}>
                    <span class="left-view"><svg  width="8px" height="8px" viewBox="0 0 8 8"
                            version="1.1" 
                            class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px" _mstVisible="6">
                            <title   _mstHash="188" _mstVisible="7">tab-radius-l
                            </title>
                            <g  id="Page-1" stroke="none" stroke-width="1" fill="none"
                                fill-rule="evenodd" data-is-selected="true" class="tab-bg" _mstVisible="7">
                                <path  d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                    id="tab-radius-l"
                                    transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                                    _mstVisible="8"></path>
                            </g>
                        </svg></span>

                    Live <span class="right-view">
                        <svg data-v-16f79fce="" width="8px" height="8px" viewBox="0 0 8 8" version="1.1"
                            
                            class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px">
                            <title data-v-16f79fce="">tab-radius-r</title>
                            <g data-v-16f79fce="" id="Page-1" stroke="none" stroke-width="1" fill="none"
                                fill-rule="evenodd" data-is-selected="false" class="tab-bg">
                                <path data-v-16f79fce="" d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                    id="tab-radius-r">
                                </path>
                            </g>
                        </svg>
                    </span>
                </li>
                <li  className={type == "tomorrow" ? "active" : ""}
              onClick={() => {
                setType("tomorrow");
                setParly(false)
              }}>
                    <span class="left-view"><svg  width="8px" height="8px" viewBox="0 0 8 8"
                            version="1.1" 
                            class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px" _mstVisible="6">
                            <title   _mstHash="188" _mstVisible="7">tab-radius-l
                            </title>
                            <g  id="Page-1" stroke="none" stroke-width="1" fill="none"
                                fill-rule="evenodd" data-is-selected="true" class="tab-bg" _mstVisible="7">
                                <path  d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                    id="tab-radius-l"
                                    transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                                    _mstVisible="8"></path>
                            </g>
                        </svg></span> Upcoming
                    <span class="right-view">
                        <svg data-v-16f79fce="" width="8px" height="8px" viewBox="0 0 8 8" version="1.1"
                            
                            class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px">
                            <title data-v-16f79fce="">tab-radius-r</title>
                            <g data-v-16f79fce="" id="Page-1" stroke="none" stroke-width="1" fill="none"
                                fill-rule="evenodd" data-is-selected="false" class="tab-bg">
                                <path data-v-16f79fce="" d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                    id="tab-radius-r">
                                </path>
                            </g>
                        </svg>
                    </span>
                </li>
                {
                  !parly &&
                  <li className={type == "leagues" ? "active" : ""}  onClick={() => {
                    setType("leagues");
                    setParly(false)
                  }}>
                        <span class="left-view"><svg  width="8px" height="8px" viewBox="0 0 8 8"
                                version="1.1" 
                                class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px" _mstVisible="6">
                                <title   _mstHash="188" _mstVisible="7">tab-radius-l
                                </title>
                                <g  id="Page-1" stroke="none" stroke-width="1" fill="none"
                                    fill-rule="evenodd" data-is-selected="true" class="tab-bg" _mstVisible="7">
                                    <path  d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                        id="tab-radius-l"
                                        transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                                        _mstVisible="8"></path>
                                </g>
                            </svg></span> Leagues <span class="right-view">
                            <svg data-v-16f79fce="" width="8px" height="8px" viewBox="0 0 8 8" version="1.1"
                                
                                class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px">
                                <title data-v-16f79fce="">tab-radius-r</title>
                                <g data-v-16f79fce="" id="Page-1" stroke="none" stroke-width="1" fill="none"
                                    fill-rule="evenodd" data-is-selected="false" class="tab-bg">
                                    <path data-v-16f79fce="" d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                        id="tab-radius-r">
                                    </path>
                                </g>
                            </svg>
                        </span>
                    </li>
                }
               
                <li onClick={() => setParly(!parly)} className={parly  ? "parlyatab active" : "parlyatab"} >
                  <span class="left-view">
                    <svg  width="8px" height="8px" viewBox="0 0 8 8"
                            version="1.1" 
                            class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px" _mstVisible="6">
                            <title   _mstHash="188" _mstVisible="7">tab-radius-l
                            </title>
                            <g  id="Page-1" stroke="none" stroke-width="1" fill="none"
                                fill-rule="evenodd" data-is-selected="true" class="tab-bg" _mstVisible="7">
                                <path  d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                    id="tab-radius-l"
                                    transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                                    _mstVisible="8"></path>
                            </g>
                        </svg></span> Parlay
                    <span class="right-view">
                        <svg data-v-16f79fce="" width="8px" height="8px" viewBox="0 0 8 8" version="1.1"
                            
                            class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px">
                            <title data-v-16f79fce="">tab-radius-r</title>
                            <g data-v-16f79fce="" id="Page-1" stroke="none" stroke-width="1" fill="none"
                                fill-rule="evenodd" data-is-selected="false" class="tab-bg">
                                <path data-v-16f79fce="" d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                    id="tab-radius-r">
                                </path>
                            </g>
                        </svg>
                    </span>
                </li>
               
                
            </ul>
            <div className="settingtab d-flex">   
              <span><FaSearch   onClick={() => setSearchTab(true)}className="ms-auto" /></span>
              <span><FaBell   className="ms-auto" /></span>
              <span><FaCog   className="ms-auto" /></span>

            </div>
        </div>
        <div class="list-s">
        <div className={gameType == "" ? "list-box active" : "list-box"}
              onClick={() => {
                setType("inplay");
                setttType("inplay");
                setGameType("");
              }}>
                <a href="#_">
                    <div class="tag-live">
                        <strong>all</strong>
                         {sportsData.length}
                    </div>
                    <div class="icon-holder-big">
                        <div class="live-icon"></div>
                    </div>
                    <div class="list-box-name">In-Play</div>
                </a>
            </div>
        <div className={"list-box"}
              onClick={() => {
                setGameType("casino");
              }}>
                <a href="javascript:void(0)">
                    
                    <div class="icon-holder-big">
                        <div class="sports-icon casino"></div>
                    </div>
                    <div class="list-box-name">Casino</div>
                </a>
            </div>
            <div className={gameType == "cricket" ? "list-box active" : "list-box"}
              onClick={() => {
                setGameType("cricket");
              }}>
                <a href="#_">
                    <div class="tag-live">
                        <strong>34</strong>  {inplayCountData?.cricketInplayCount || 0}
                    </div>
                    <div class="icon-holder-big">
                        <div class="sports-icon cricket"></div>
                    </div>
                    <div class="list-box-name">Cricket</div>
                </a>
            </div>
            <div className={gameType == "soccer" ? "list-box active" : "list-box"}
              onClick={() => {
                setGameType("soccer");
              }}>
                <a href="#_">
                    <div class="tag-live">
                        <strong>50</strong>  {inplayCountData?.soccerInplayCount || 0}
                    </div>
                    <div class="icon-holder-big">
                        <div class="sports-icon soccer"></div>
                    </div>
                    <div class="list-box-name">Soccer</div>
                </a>
            </div>
            <div className={gameType == "tennis" ? "list-box active" : "list-box"}
              onClick={() => {
                setGameType("tennis");
              }}>
                <a href="#_">
                    <div class="tag-live">
                        <strong>8</strong> {inplayCountData?.tennisInplayCount || 0}
                    </div>
                    <div class="icon-holder-big">
                        <div class="sports-icon tennis"></div>
                    </div>
                    <div class="list-box-name">Tennis</div>
                </a>
            </div>
            
            <div className={gameType == "icehockey" ? "list-box active" : "list-box"}
              onClick={() => {
                setGameType("icehockey");
              }}>
                <a href="#_">
                    <div class="tag-live">
                        <strong>8</strong> {inplayCountData?.tennisInplayCount || 0}
                    </div>
                    <div class="icon-holder-big">
                        <div class="sports-icon ice-hockey"></div>
                    </div>
                    <div class="list-box-name">Ice Hockey</div>
                </a>
            </div>
            <div className={gameType == "motorsport" ? "list-box active" : "list-box"}
              onClick={() => {
                setGameType("motorsport");
              }}>
                <a href="#_">
                    <div class="tag-live">
                        <strong>8</strong> {inplayCountData?.tennisInplayCount || 0}
                    </div>
                    <div class="icon-holder-big">
                        <div class="sports-icon motor-sport"></div>
                    </div>
                    <div class="list-box-name">Motor Sport</div>
                </a>
            </div>


        </div>
        
       { gameType=="casino"&&
        
           <Casino  homclass={"home-class"}/>
       }
        {
          type!=="leagues" ?
          <>
          {!sketon?

       
          <div class="data-t">
           {  type == "inplay" ? 
           <>
            { sportsData && sportsData.length > 0 && (
                 <>
                  {sportsData?.filter((res) => res.gameType == "cricket")?.length > 0 &&
                   <h3 className="sports-heading">
                    <div class="icon-holder-big">
                        <div class="sports-icon cricket"></div>
                    </div>
                    <div className="h-game">Cricket ({sportsData?.filter((res) => res.gameType == "cricket")?.length})</div></h3>}

                 {sportsData?.filter((res) => res.gameType == "cricket")?.length > 0 && sportsData?.filter((res) => res.gameType == "cricket")?.map((item) => {
                    
                     let obj = moment.duration(
                       moment(item?.eventDateTime).diff(moment(new Date()))
                     )?._data;
                     let obj1=helpers.matchDateTime(item.eventDateTime).split(" ");

                     return (
                      <>
                    
                   <div class="market-data" onClick={() =>
                     navigate(
                       `/full-market/${item?.marketId}/${item?.eventId}`
                     )
                   }>
                     <div className="d-flex">
                    

                     {item.status == "in_play" ? (
        <div class="date-time in-play">
            <div class="animate">
                <ul class="flip-animation">
                    <li class="time_date">
                    <span class="time">Today</span>
                    <span class="date"> {obj1.length>2?obj1[1]+" "+obj1[2]:obj1[0]+" "+obj1[1]}</span>
                    </li>
                    <li _ngcontent-cgj-c85=""><span class="in-play-light">
                            <div class="icon-holder-small">
                                <div class="sports-icon inplay-light-icon"></div>
                            </div>In-Play
                        </span></li>
                </ul>
            </div>
        </div>):(
          <div class="date-time">
          <div>
           
              <ul>
                  <li class="time_date">
                      <span class="time">{obj1.length>2?obj1[0]:"Today"}</span>
                      <span class="date"> {obj1.length>2?obj1[1]+" "+obj1[2]:obj1[0]+" "+obj1[1]}</span>
                  </li>
              </ul>
          </div>
      </div>
        )}
                       <a href="#_">
                           <div class="game-name">
                               <div class="versis-img">  {item?.eventName.split(" v ")[0]}
                                   <span class="vs-img notranslate">
                                       <svg 
                                           width="18" height="18" viewBox="0 0 65.141 65.34">
                                           <defs>
                                               <clipPath id="clip-path">
                                                   <rect width="65.141" height="65.34" fill="none">
                                                   </rect>
                                               </clipPath>
                                           </defs>
                                           <g id="Repeat_Grid_1" data-name="Repeat Grid 1" clip-path="url(#clip-path)">
                                               <g id="Group_3" data-name="Group 3" transform="translate(-757.532 -436.426)">
                                                   <path id="Subtraction_2" data-name="Subtraction 2"
                                                       d="M27,54A27.007,27.007,0,0,1,16.49,2.122,27.007,27.007,0,0,1,37.51,51.878,26.832,26.832,0,0,1,27,54Zm5.451-22.123L28.6,32.7a6.748,6.748,0,0,0,3.262,4.27,11.626,11.626,0,0,0,5.729,1.37,10.188,10.188,0,0,0,6.324-1.861,6.086,6.086,0,0,0,2.442-5.1A6.335,6.335,0,0,0,45.2,27.7c-.77-1.106-2.3-1.955-4.56-2.522L36.9,24.24c-2.031-.5-3.061-1.392-3.061-2.643a2.414,2.414,0,0,1,.975-1.966,4.488,4.488,0,0,1,2.828-.773c2.117,0,3.483.84,4.061,2.5l3.77-.935a5.612,5.612,0,0,0-2.844-3.513,10.3,10.3,0,0,0-5.068-1.24,8.643,8.643,0,0,0-5.607,1.78,5.813,5.813,0,0,0-2.191,4.73,6.448,6.448,0,0,0,.62,2.787,5.048,5.048,0,0,0,1.651,2.022,10.4,10.4,0,0,0,3.191,1.281l4.206,1.1a3.722,3.722,0,0,1,2.208,1.2,2.938,2.938,0,0,1,.516,1.668,2.512,2.512,0,0,1-1.136,2.078,5.426,5.426,0,0,1-3.247.838,6.961,6.961,0,0,1-3.641-.818A3.639,3.639,0,0,1,32.451,31.877ZM9.065,16.006,15.848,38h4.028l6.881-21.994h-3.3L18.3,32.86,13.254,16.006Z"
                                                       transform="translate(763 442)"></path>
                                                   <path id="Path_2" data-name="Path 2"
                                                       d="M768.4,489.5l2.639,2.463-9.473,9.438h0s-1.527,1.734-3.042.172a1.77,1.77,0,0,1,.2-2.8Z"
                                                       transform="translate(1580.574 938.646) rotate(180)"></path>
                                                   <path id="Path_3" data-name="Path 3"
                                                       d="M768.4,489.5l2.639,2.463-9.473,9.438h0s-1.527,1.734-3.042.172a1.77,1.77,0,0,1,.2-2.8Z"
                                                       transform="translate(-0.369 -0.455)"></path>
                                               </g>
                                           </g>
                                       </svg></span>   {item?.eventName.split(" v ")[1]}
                               </div>
                               <div class="game-list-time">
                                   <span class="game-subtitle">{item?.seriesName}</span>
                               </div>
                           </div>
                       </a>
                      </div>
                       <div class="market-count">
                           <div class="right badge-right">
                                {item?.channel !== "false" && (
                                 <a href="#_"><FaTv /></a>
                               )}
                               {item?.hasMatchOdds && 
                                    <a>MO<span class="badge">{item?.marketCount || 0}</span></a>
                               }
                             
                               {item?.hasFancy && 
                                    <a>F<span class="badge">{item?.fancyCount || Math.floor(Math.random() * 11)}</span></a>
                               }
                               {item?.hasBookmaker && 
                                    <a>B<span class="badge">{2}</span></a>
                               }
                             
                           </div>
                       </div>
                   </div>
                   </>
                    );
   
                 })}
                  {sportsData?.filter((res) => res.gameType == "soccer")?.length > 0 &&
                   <h3 className="sports-heading">
                    <div class="icon-holder-big">
                        <div class="sports-icon soccer"></div>
                    </div>
                    <div className="h-game">
                      Soccer ({sportsData?.filter((res) => res.gameType == "soccer")?.length})</div></h3>}
                   {sportsData?.filter((res) => res.gameType == "soccer")?.length > 0 && sportsData?.filter((res) => res.gameType == "soccer")?.map((item) => {
                    
                    let obj = moment.duration(
                      moment(item?.eventDateTime).diff(moment(new Date()))
                    )?._data;
                    let obj1=helpers.matchDateTime(item.eventDateTime).split(" ");

                    return (
                     <>
                    
                  <div class="market-data" onClick={() =>
                    navigate(
                      `/full-market/${item?.marketId}/${item?.eventId}`
                    )
                  }>
                    <div className="d-flex">
                   

                    {item.status == "in_play" ? (
        <div class="date-time in-play">
            <div class="animate">
                <ul class="flip-animation">
                    <li class="time_date">
                    <span class="time">Today</span>
                    <span class="date"> {obj1.length>2?obj1[1]+" "+obj1[2]:obj1[0]+" "+obj1[1]}</span>
                    </li>
                    <li _ngcontent-cgj-c85=""><span class="in-play-light">
                            <div class="icon-holder-small">
                                <div class="sports-icon inplay-light-icon"></div>
                            </div>In-Play
                        </span></li>
                </ul>
            </div>
        </div>):(
          <div class="date-time">
          <div>
           
              <ul>
                  <li class="time_date">
                      <span class="time">{obj1.length>2?obj1[0]:"Today"}</span>
                      <span class="date"> {obj1.length>2?obj1[1]+" "+obj1[2]:obj1[0]+" "+obj1[1]}</span>
                  </li>
              </ul>
          </div>
      </div>
        )}
      
                      <a href="#_">
                          <div class="game-name">
                              <div class="versis-img">  {item?.eventName.split(" v ")[0]}
                                  <span class="vs-img notranslate">
                                      <svg 
                                          width="18" height="18" viewBox="0 0 65.141 65.34">
                                          <defs>
                                              <clipPath id="clip-path">
                                                  <rect width="65.141" height="65.34" fill="none">
                                                  </rect>
                                              </clipPath>
                                          </defs>
                                          <g id="Repeat_Grid_1" data-name="Repeat Grid 1" clip-path="url(#clip-path)">
                                              <g id="Group_3" data-name="Group 3" transform="translate(-757.532 -436.426)">
                                                  <path id="Subtraction_2" data-name="Subtraction 2"
                                                      d="M27,54A27.007,27.007,0,0,1,16.49,2.122,27.007,27.007,0,0,1,37.51,51.878,26.832,26.832,0,0,1,27,54Zm5.451-22.123L28.6,32.7a6.748,6.748,0,0,0,3.262,4.27,11.626,11.626,0,0,0,5.729,1.37,10.188,10.188,0,0,0,6.324-1.861,6.086,6.086,0,0,0,2.442-5.1A6.335,6.335,0,0,0,45.2,27.7c-.77-1.106-2.3-1.955-4.56-2.522L36.9,24.24c-2.031-.5-3.061-1.392-3.061-2.643a2.414,2.414,0,0,1,.975-1.966,4.488,4.488,0,0,1,2.828-.773c2.117,0,3.483.84,4.061,2.5l3.77-.935a5.612,5.612,0,0,0-2.844-3.513,10.3,10.3,0,0,0-5.068-1.24,8.643,8.643,0,0,0-5.607,1.78,5.813,5.813,0,0,0-2.191,4.73,6.448,6.448,0,0,0,.62,2.787,5.048,5.048,0,0,0,1.651,2.022,10.4,10.4,0,0,0,3.191,1.281l4.206,1.1a3.722,3.722,0,0,1,2.208,1.2,2.938,2.938,0,0,1,.516,1.668,2.512,2.512,0,0,1-1.136,2.078,5.426,5.426,0,0,1-3.247.838,6.961,6.961,0,0,1-3.641-.818A3.639,3.639,0,0,1,32.451,31.877ZM9.065,16.006,15.848,38h4.028l6.881-21.994h-3.3L18.3,32.86,13.254,16.006Z"
                                                      transform="translate(763 442)"></path>
                                                  <path id="Path_2" data-name="Path 2"
                                                      d="M768.4,489.5l2.639,2.463-9.473,9.438h0s-1.527,1.734-3.042.172a1.77,1.77,0,0,1,.2-2.8Z"
                                                      transform="translate(1580.574 938.646) rotate(180)"></path>
                                                  <path id="Path_3" data-name="Path 3"
                                                      d="M768.4,489.5l2.639,2.463-9.473,9.438h0s-1.527,1.734-3.042.172a1.77,1.77,0,0,1,.2-2.8Z"
                                                      transform="translate(-0.369 -0.455)"></path>
                                              </g>
                                          </g>
                                      </svg></span>   {item?.eventName.split(" v ")[1]}
                              </div>
                              <div class="game-list-time">
                                  <span class="game-subtitle">{item?.seriesName}</span>
                              </div>
                          </div>
                      </a>
                     </div>
                      <div class="market-count">
                          <div class="right badge-right">
                          {item?.channel !== "false" && (
                                 <a href="#_"><FaTv /></a>
                               )}
                                 {item?.hasMatchOdds && 
                                    <a>MO<span class="badge">{item?.marketCount || 0}</span></a>
                               }
                             
                               {item?.hasFancy && 
                                    <a>F<span class="badge">{item?.fancyCount || Math.floor(Math.random() * 11)}</span></a>
                               }
                               {item?.hasBookmaker && 
                                    <a>B<span class="badge">{2}</span></a>
                               }
                             
                          </div>
                      </div>
                  </div>
                  </>
                   );
  
                })}
                 {sportsData?.filter((res) => res.gameType == "tennis")?.length > 0 &&
                 <h3 className="sports-heading">
                    <div class="icon-holder-big">
                        <div class="sports-icon tennis"></div>
                    </div>
                    <div className="h-game">
                      Tennis ({sportsData?.filter((res) => res.gameType == "tennis")?.length})</div></h3>}
                 {sportsData?.filter((res) => res.gameType == "tennis")?.length > 0 && sportsData?.filter((res) => res.gameType == "tennis")?.map((item) => {
                    
                    let obj = moment.duration(
                      moment(item?.eventDateTime).diff(moment(new Date()))
                    )?._data;
                    let obj1=helpers.matchDateTime(item.eventDateTime).split(" ");

                    return (
                     <>
                    
                  <div class="market-data" onClick={() =>
                    navigate(
                      `/full-market/${item?.marketId}/${item?.eventId}`
                    )
                  }>
                    <div className="d-flex">
                   

                    {item.status == "in_play" ? (
        <div class="date-time in-play">
            <div class="animate">
                <ul class="flip-animation">
                    <li class="time_date">
                    <span class="time">Today</span>
                    <span class="date"> {obj1.length>2?obj1[1]+" "+obj1[2]:obj1[0]+" "+obj1[1]}</span>
                    </li>
                    <li _ngcontent-cgj-c85=""><span class="in-play-light">
                            <div class="icon-holder-small">
                                <div class="sports-icon inplay-light-icon"></div>
                            </div>In-Play
                        </span></li>
                </ul>
            </div>
        </div>):(
          <div class="date-time">
          <div>
           
              <ul>
                  <li class="time_date">
                      <span class="time">{obj1.length>2?obj1[0]:"Today"}</span>
                      <span class="date"> {obj1.length>2?obj1[1]+" "+obj1[2]:obj1[0]+" "+obj1[1]}</span>
                  </li>
              </ul>
          </div>
      </div>
        )}
      
                      <a href="#_">
                          <div class="game-name">
                              <div class="versis-img">  {item?.eventName.split(" v ")[0]}
                                  <span class="vs-img notranslate">
                                      <svg 
                                          width="18" height="18" viewBox="0 0 65.141 65.34">
                                          <defs>
                                              <clipPath id="clip-path">
                                                  <rect width="65.141" height="65.34" fill="none">
                                                  </rect>
                                              </clipPath>
                                          </defs>
                                          <g id="Repeat_Grid_1" data-name="Repeat Grid 1" clip-path="url(#clip-path)">
                                              <g id="Group_3" data-name="Group 3" transform="translate(-757.532 -436.426)">
                                                  <path id="Subtraction_2" data-name="Subtraction 2"
                                                      d="M27,54A27.007,27.007,0,0,1,16.49,2.122,27.007,27.007,0,0,1,37.51,51.878,26.832,26.832,0,0,1,27,54Zm5.451-22.123L28.6,32.7a6.748,6.748,0,0,0,3.262,4.27,11.626,11.626,0,0,0,5.729,1.37,10.188,10.188,0,0,0,6.324-1.861,6.086,6.086,0,0,0,2.442-5.1A6.335,6.335,0,0,0,45.2,27.7c-.77-1.106-2.3-1.955-4.56-2.522L36.9,24.24c-2.031-.5-3.061-1.392-3.061-2.643a2.414,2.414,0,0,1,.975-1.966,4.488,4.488,0,0,1,2.828-.773c2.117,0,3.483.84,4.061,2.5l3.77-.935a5.612,5.612,0,0,0-2.844-3.513,10.3,10.3,0,0,0-5.068-1.24,8.643,8.643,0,0,0-5.607,1.78,5.813,5.813,0,0,0-2.191,4.73,6.448,6.448,0,0,0,.62,2.787,5.048,5.048,0,0,0,1.651,2.022,10.4,10.4,0,0,0,3.191,1.281l4.206,1.1a3.722,3.722,0,0,1,2.208,1.2,2.938,2.938,0,0,1,.516,1.668,2.512,2.512,0,0,1-1.136,2.078,5.426,5.426,0,0,1-3.247.838,6.961,6.961,0,0,1-3.641-.818A3.639,3.639,0,0,1,32.451,31.877ZM9.065,16.006,15.848,38h4.028l6.881-21.994h-3.3L18.3,32.86,13.254,16.006Z"
                                                      transform="translate(763 442)"></path>
                                                  <path id="Path_2" data-name="Path 2"
                                                      d="M768.4,489.5l2.639,2.463-9.473,9.438h0s-1.527,1.734-3.042.172a1.77,1.77,0,0,1,.2-2.8Z"
                                                      transform="translate(1580.574 938.646) rotate(180)"></path>
                                                  <path id="Path_3" data-name="Path 3"
                                                      d="M768.4,489.5l2.639,2.463-9.473,9.438h0s-1.527,1.734-3.042.172a1.77,1.77,0,0,1,.2-2.8Z"
                                                      transform="translate(-0.369 -0.455)"></path>
                                              </g>
                                          </g>
                                      </svg></span>   {item?.eventName.split(" v ")[1]}
                              </div>
                              <div class="game-list-time">
                                  <span class="game-subtitle">{item?.seriesName}</span>
                              </div>
                          </div>
                      </a>
                     </div>
                      <div class="market-count">
                          <div class="right badge-right">
                          {item?.channel !== "false" && (
                                 <a href="#_"><FaTv /></a>
                               )}
                                 {item?.hasMatchOdds && 
                                    <a>MO<span class="badge">{item?.marketCount || 0}</span></a>
                               }
                             
                               {item?.hasFancy && 
                                    <a>F<span class="badge">{item?.fancyCount ||  Math.floor(Math.random() * 11)}</span></a>
                               }
                               {item?.hasBookmaker && 
                                    <a>B<span class="badge">{2}</span></a>
                               }
                          </div>
                      </div>
                  </div>
                  </>
                   );
  
                })}
                </>
                    
                
              )}

           
        
            </>
            :
           
sportsData.length > 0 &&
    sportsData.map((item) => {
      let obj = moment.duration(
        moment(item?.eventDateTime).diff(moment(new Date()))
      )?._data;
     
      let obj1=helpers.matchDateTime(item.eventDateTime).split(" ");
      
       
      return (
    <div class="market-data" onClick={() =>
      navigate(
        `/full-market/${item?.marketId}/${item?.eventId}`
      )
    }>
          <div className="d-flex">
          {item.status == "in_play" ? (
            <div class="date-time in-play">
                <div class="animate">
                    <ul class="flip-animation">
                        <li class="time_date">
                        <span class="time">Today</span>
                        <span class="date"> {obj1.length>2?obj1[1]+" "+obj1[2]:obj1[0]+" "+obj1[1]}</span>
                        </li>
                        <li _ngcontent-cgj-c85=""><span class="in-play-light">
                                <div class="icon-holder-small">
                                    <div class="sports-icon inplay-light-icon"></div>
                                </div>In-Play
                            </span></li>
                    </ul>
                </div>
            </div>):(
              <div class="date-time">
              <div>
              
                  <ul>
                      <li class="time_date">
                          <span class="time">{obj1.length>2?obj1[0]:"Today"}</span>
                          <span class="date"> {obj1.length>2?obj1[1]+" "+obj1[2]:obj1[0]+" "+obj1[1]}</span>
                      </li>
                  </ul>
              </div>
          </div>
        )}

        <a href="#_">
            <div class="game-name">
                <div class="versis-img">  {item?.eventName.split(" v ")[0]}
                    <span class="vs-img notranslate">
                        <svg 
                            width="18" height="18" viewBox="0 0 65.141 65.34">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect width="65.141" height="65.34" fill="none">
                                    </rect>
                                </clipPath>
                            </defs>
                            <g id="Repeat_Grid_1" data-name="Repeat Grid 1" clip-path="url(#clip-path)">
                                <g id="Group_3" data-name="Group 3" transform="translate(-757.532 -436.426)">
                                    <path id="Subtraction_2" data-name="Subtraction 2"
                                        d="M27,54A27.007,27.007,0,0,1,16.49,2.122,27.007,27.007,0,0,1,37.51,51.878,26.832,26.832,0,0,1,27,54Zm5.451-22.123L28.6,32.7a6.748,6.748,0,0,0,3.262,4.27,11.626,11.626,0,0,0,5.729,1.37,10.188,10.188,0,0,0,6.324-1.861,6.086,6.086,0,0,0,2.442-5.1A6.335,6.335,0,0,0,45.2,27.7c-.77-1.106-2.3-1.955-4.56-2.522L36.9,24.24c-2.031-.5-3.061-1.392-3.061-2.643a2.414,2.414,0,0,1,.975-1.966,4.488,4.488,0,0,1,2.828-.773c2.117,0,3.483.84,4.061,2.5l3.77-.935a5.612,5.612,0,0,0-2.844-3.513,10.3,10.3,0,0,0-5.068-1.24,8.643,8.643,0,0,0-5.607,1.78,5.813,5.813,0,0,0-2.191,4.73,6.448,6.448,0,0,0,.62,2.787,5.048,5.048,0,0,0,1.651,2.022,10.4,10.4,0,0,0,3.191,1.281l4.206,1.1a3.722,3.722,0,0,1,2.208,1.2,2.938,2.938,0,0,1,.516,1.668,2.512,2.512,0,0,1-1.136,2.078,5.426,5.426,0,0,1-3.247.838,6.961,6.961,0,0,1-3.641-.818A3.639,3.639,0,0,1,32.451,31.877ZM9.065,16.006,15.848,38h4.028l6.881-21.994h-3.3L18.3,32.86,13.254,16.006Z"
                                        transform="translate(763 442)"></path>
                                    <path id="Path_2" data-name="Path 2"
                                        d="M768.4,489.5l2.639,2.463-9.473,9.438h0s-1.527,1.734-3.042.172a1.77,1.77,0,0,1,.2-2.8Z"
                                        transform="translate(1580.574 938.646) rotate(180)"></path>
                                    <path id="Path_3" data-name="Path 3"
                                        d="M768.4,489.5l2.639,2.463-9.473,9.438h0s-1.527,1.734-3.042.172a1.77,1.77,0,0,1,.2-2.8Z"
                                        transform="translate(-0.369 -0.455)"></path>
                                </g>
                            </g>
                        </svg></span>   {item?.eventName.split(" v ")[1]}
                </div>
                <div class="game-list-time">
                    <span class="game-subtitle">{item?.seriesName}</span>
                </div>
            </div>
        </a>
       </div>
        <div class="market-count">
            <div class="right badge-right">
            {item?.channel !== "false" && (
                                 <a href="#_"><FaTv /></a>
                               )}
                                 {item?.hasMatchOdds && 
                                    <a>MO<span class="badge">{item?.marketCount || 0}</span></a>
                               }
                             
                               {item?.hasFancy && 
                                    <a>F<span class="badge">{item?.fancyCount || Math.floor(Math.random() * 11)}</span></a>
                               }
                               {item?.hasBookmaker && 
                                    <a>B<span class="badge">{2}</span></a>
                               }
            </div>
        </div>
    </div>
     );
    })}
  
          
          
        </div>
      
        :
        <div className="sketonc"><Skeleton count={20} baseColor="#303030" highlightColor="#7e8081"/> </div>
        
        }
        </>
          
          :
          isEmpty(selected) && (
            <div>
             
              {updatedList?.length > 0 ? (
                <div className="d-flex p-3 leage-list main-casino-wrapper">
                  {/* <aside className="text-center">
                  <ul className="p-0 mb-0">
                    <li>C</li>
                    <li>I</li>
                    <li>O</li>
                  </ul>
                </aside> */}
                  <div
                    className={`main-list w-100 ${
                      updatedList?.length > 7 ? "mb-5" : ""
                    }`}
                  >
                    <h6 className="mb-3">Popular</h6>
                    {updatedList?.map((item, index) => {
                      return (
                        <div className="w-100 mb-1 match-list">
                          <ul className="p-0 m-0">
                            <li onClick={() => setSelected(item)}>
                              {item?.seriesName}
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="d-flex p-3 justify-content-center align-items-center leage-list main-casino-wrapper">
                  <NoEvent />
                </div>
              )}
            </div>
          )}
  
          {/* second-level */}
          {!isEmpty(selected) && (
            <div className="second-level-leage">
              <div className="balance-label position-relative text-start ps-5">
                <button
                  onClick={() => setSelected({})}
                  className="bg-transparent border-0 text-white position-absolute start-0 pt-0"
                >
                  <FaAngleLeft className="fs-3" />
                </button>
                {selected?.seriesName}
              </div>
  
              <div className="d-flex p-3 px-4 leage-list main-casino-wrapper">
                <div
                  className={`main-list w-100 ${
                    selected?.matchList?.length > 7 ? "mb-5" : ""
                  }`}
                >
                  {selected?.matchList?.map((item, index) => {
                    return (
                      <div className="w-100 mb-3 match-list">
                        <ul className="p-0 m-0">
                          <li
                            onClick={() =>
                              navigate(
                                `/full-market/${item?.marketId}/${item?.eventId}`
                              )
                            }
                          >
                            {item?.runners?.map((res) => {
                              return (
                                <span className="d-block">{res?.RunnerName}</span>
                              );
                            })}
                          </li>
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
  
        
        
    </div></>)}
    </LayoutNew>
  );
};

export default Home;
