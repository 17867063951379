import React, { useState } from "react";
import SubCasino from "./SubCasino";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const HeadContainer = ({ header, setHeader, setSubCasino, subCasino,homclass }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    cssEase: 'linear',
  };

  return (
    <>
      <Slider {...settings} className={"casino-category " +homclass}>
        <div className="category-item">
          <div
            onClick={() => {
              setHeader("popular");
            }}
            className={header === "popular" && "active"}
          >
            <h6>Popular</h6>
            <div>
              {header === "popular" ? (
                <img
                  src="../assets/images/casino-icon/hover-popular.svg"
                  alt=""
                />
              ) : (
                <img src="../assets/images/casino-icon/popular.svg" alt="" />
              )}
            </div>
          </div>
        </div>
        <div className="category-item">
          <div
            onClick={() => {
              setHeader("live");
            }}
            className={header === "live" && "active"}
          >
            <h6>Live</h6>
            <div>
              {header === "live" ? (
                <img src="../assets/images/casino-icon/hover-live.svg" alt="" />
              ) : (
                <img src="../assets/images/casino-icon/icon-live.svg" alt="" />
              )}
            </div>
          </div>
        </div>
        <div className="category-item">
          <div
            onClick={() => {
              setHeader("table");
            }}
            className={header === "table" && "active"}
          >
            <h6>Table</h6>
            <div>
              {header === "table" ? (
                <img
                  src="../assets/images/casino-icon/hover-table.svg"
                  alt=""
                />
              ) : (
                <img src="../assets/images/casino-icon/icon-table.svg" alt="" />
              )}
            </div>
          </div>
        </div>
        <div className="category-item">
          <div
            onClick={() => {
              setHeader("slot");
            }}
            className={header === "slot" && "active"}
          >
            <h6>Slot</h6>
            <div>
              {header === "slot" ? (
                <img src="../assets/images/casino-icon/hover-slot.svg" alt="" />
              ) : (
                <img src="../assets/images/casino-icon/icon-slot.svg" alt="" />
              )}
            </div>
          </div>
        </div>
        <div className="category-item">
          <div
            onClick={() => {
              setHeader("fishing");
            }}
            className={header === "fishing" && "active"}
          >
            <h6>Fishing</h6>
            <div>
              {header === "fishing" ? (
                <img
                  src="../assets/images/casino-icon/hover-fishing.svg"
                  alt=""
                />
              ) : (
                <img
                  src="../assets/images/casino-icon/icon-fishing.svg"
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
        <div className="category-item">
          <div
            onClick={() => {
              setHeader("egame");
            }}
            className={header === "egame" && "active"}
          >
            <h6>Egame</h6>
            <div>
              {header === "egame" ? (
                <img
                  src="../assets/images/casino-icon/hover-egame.svg"
                  alt=""
                />
              ) : (
                <img src="../assets/images/casino-icon/egame.svg" alt="" />
              )}
            </div>
          </div>
        </div>
        <div className="category-item">
          <div
            onClick={() => {
              setHeader("lottery");
            }}
            className={header === "lottery" && "active"}
          >
            <h6>Lottery</h6>
            <div>
              {header === "lottery" ? (
                <img
                  src="../assets/images/casino-icon/hover-egame.svg"
                  alt=""
                />
              ) : (
                <img src="../assets/images/casino-icon/egame.svg" alt="" />
              )}
            </div>
          </div>
        </div>
      </Slider>
      {header === "popular" ? (
        ""
      ) : (
        <SubCasino
          setSubCasino={setSubCasino}
          subCasino={subCasino}
          header={header}
        />
      )}
    </>
  );
};

export default HeadContainer;
