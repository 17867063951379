import React, { useContext,useEffect } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import FlagIcon from "../assets/imagesNew/india-icon.png";
import FlagIconBn from "../assets/imagesNew/bangladesh-flag-round-circle-icon.png";
import { isEmpty } from "lodash";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { DepositWithrawData, DepositWithrawDataNew ,bottomNavigation} from "../Utils/constants";


import BetSlipContext from "../context/BetSlipContext";
import MyBets from "./MyBets";
import betsIcon from "../assets/footer-icons/mybets.png";
import svgLeft from "../assets/images/svg-left.svg";
import svgRight from "../assets/images/svg-right.svg";
const FooterNew = () => {
  const { user, lang, setEn, setBr,handleShowLoginNotification  } = useContext(AuthContext);
  let location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showBet, setShowBet } = useContext(BetSlipContext);

  return (
    <>
  { !isEmpty(user) &&
  <div class="s-float-button"
    onClick={() => {
      
        if (!isEmpty(user)) {
          setShowBet(true);
         
        } else {
          navigate('/login');
        }
      
    }}
    >
    <div class="s-items-center">
      <div  class="bg-betSlip-icon-light" style={{ bottom: !isEmpty(user) ? `50px`:"0"}}>
        <span  class="s-icon-list"> 
          <img className="svg-left" src={svgLeft} />
          <img src={betsIcon} />
          <img className="svg-right" src={svgRight} />
        
        </span>
        </div>
    </div>
    </div>
 } 
    <div className="footer-new">

      <ul>
{/*         
        {isEmpty(user) ? (
          <>
          <li className="language-select">
          <div
            className="language-select-div"
            onClick={() => {
              if (lang == "bn") {
                setEn();
              } else {
                setBr();
              }
            }}
          >
            <img src={lang == "bn" ? FlagIcon : FlagIconBn} alt="" />{" "}
            {lang == "bn" ? "INR" : "BDT"} <br />{" "}
            {lang == "bn" ? "English" : "Bangla"}
          </div>
        </li>
        <li className="register-button">
              <Link to="/register">{t("Sign_up")}</Link>
            </li>
            <li className="login-button">
              <Link to="/login">{t("Login")}</Link>
            </li>
          </>
        ) : ( */}
          <>
          {!isEmpty(user) ? (

          bottomNavigation && bottomNavigation?.map((item, index) => {
              return (
            <li className={location.pathname === item?.link ? "active" : ""}
            onClick={() => {
              if (item?.id == 5) {
                if (!isEmpty(user)) {
                  setShowBet(true);
                 
                } else {
                  handleShowLoginNotification();
                }
              } else {
                navigate(item?.link);
              }
            }}>
              <div className="payment-box footer-payment-box ">
                <figure>
                  <img src={location.pathname === item?.link?item?.iconActive:item?.icon} />
                </figure>
                <span>{item?.menu}</span>
              </div>
            </li>
              );
          })
        ):""}
           
          </>
        {/* )} */}
      </ul>
      {showBet && <MyBets setShowBet={setShowBet} />}
    </div>
    </>
  );
};

export default FooterNew;
